









import Vue from 'vue';
import info from '!!raw-loader!@/assets/gitInfo.txt';

export default Vue.extend({
  computed: {
    info() {
      return info;
    },
  },
});
